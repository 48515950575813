import { withOktaAuth } from '@okta/okta-react';
import SafariAutofillMessage from 'components/atoms/SafariAutofillMessage';
import { APP_ROOT_CONTENT_STYLE, IDENTITY_DELEGATION_FLAG, USER_INFO } from 'helpers/constant';
import { getUserInfo, isOKTAStorageAvailableWithRequiredkey } from 'helpers/utilCommon';
import { get } from 'lodash';
import { LOGIN_USER_MODEL } from 'models/common.models';
import { ComponentUrls, StaticPages } from 'models/navigation.models';
import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from '../../../asyncComponent';
import { getCookie, setCookie } from '../../../helpers/manageCookies';
import { isPublicRoutes, PrivateRoutes, PublicRoute } from '../../../hoc/RouteHOC';
import { cookieKeysEnum } from '../../../models/common.models';
import { setAppLoader } from '../../../redux/app/actions.js';
import CustomLoader from '../../atoms/CustomLoader';
import ScrollToTop from '../../atoms/ScrollToTop';
import ToastMessage from '../../atoms/ToastMessage';
import FooterComp from '../../organisms/Footer';
import HeaderComp from '../../organisms/Header';
import AventriRegistration from '../aventri';
import MyChildAppComponents from '../myChildAppComponents';
import MyRosterComponents from './RosterChildApp';
import { verifySessionAndMaintainRedirectOnExternalApp, handleLogoutRedirect } from "../../../helpers/OktaUtility";
import utilCommon from '../../../helpers/utilCommon';
import IdentityDelegationBanner from '../../organisms/IdentityDelegationBanner';
import { withFlagFeature } from 'providers/flagFeature';
import { datadogLogs } from '@datadog/browser-logs';

const MyAccountRoutes = asyncComponent(() => {
  return import('../../../chunks/myAccount.chunk');
});

const DownloadDocumentsRoutes = asyncComponent(() => {
  return import('../../../chunks/downloadDocuments.chunk');
});

const ApplicationsRoutes = asyncComponent(() => {
  return import('../../../chunks/applications.chunk');
});

const StaticPagesRoutes = asyncComponent(() => {
  return import('../../../chunks/staticPages.chunk');
});


class App extends Component {
  state = {
    isRestrictedUrl: true,
    isRedirectUrl: false,
    isMemberStillLoginInAnotherTab: '',
    identityDelegation: null
  }
  // The variable is use full to handle if no content/children appears.
  midContentSection = null;
  componentDidMount = () => {
    if (this.state.isRestrictedUrl) {
      this.verifyUserInfoHandler();
      this.props.verifyMECallForSession();
      if (this.props.isLoadOKTAConfigurationTest) {
        this.loadOKTAConfiguration();
      }
    }
    if (!getCookie(cookieKeysEnum["BANNER-DISPLAY"])) {
      this.loadBannerInfo();
    }
    this.props.checkUser();
    this.midContentSection = setInterval(this.loadRootChildTags, 1000);
    // Triggered when a token has been renewed
    this.props.OIDC.tokenManager.on('renewed', function (key, newToken, oldToken) {
      const sessionOktaToken = JSON.parse(sessionStorage.getItem('okta-token-storage'));
      sessionOktaToken[key] = newToken;
      localStorage.setItem('okta-token-storage', JSON.stringify(sessionOktaToken))
    });
  }

  componentWillMount() {
    const oktaToken = localStorage.getItem('okta-token-storage');
    if (oktaToken && !sessionStorage.getItem('okta-token-storage')) {
      sessionStorage.setItem('okta-token-storage', oktaToken);
    }

    const identityDelegation = this.getIdentityDelegation();
    this.setState({ identityDelegation });

    window.addEventListener("storage", (e) => {
      if (e.key === 'isMemberStillLoginInAnotherTab') {
        this.setState({ isMemberStillLoginInAnotherTab: e.newValue });
      }
      const identityDelegation = this.getIdentityDelegation();
      if (identityDelegation) {
        const prevIdentityDelegationValue = this.state.identityDelegation;
        if (!prevIdentityDelegationValue || prevIdentityDelegationValue.proxyMemberMAEUserId !== identityDelegation.proxyMemberMAEUserId) {
          window.location.replace(window.location.origin);
        }
      }
    });
    const urlParams = new URLSearchParams(window.location.search);
    const isRedirectFromRM = urlParams.get('rmLogout');
    if (isRedirectFromRM) {
      sessionStorage.setItem('redirect-url', '/RMLogin');
      this.setState({ isRedirectUrl: true });
    }
    const parentPathname = this.props.location.pathname.toLowerCase().split('/')[1];
    const protectedURLLists = [
      "/MyASTM",
      "/ballot",
      "/wi",
      "/standard",
      "/meeting",
      "/RMLogin"
    ];
    const isProtectedParantRoute = protectedURLLists.find((routesPath) => {
      return routesPath.toLowerCase() === `/${parentPathname}`;
    });
    if (isProtectedParantRoute || isRedirectFromRM) {
      verifySessionAndMaintainRedirectOnExternalApp();
    }
    if (this.checkRestrictedUrl()) {
      this.unlisten = this.props.history.listen((location, action) => {
        // TODO : Check Session Call on Every  Navigation URL change
        this.props.verifyMECallForSession();
      });
    }
  }

  getIdentityDelegation = () => {
    const identityDelegation = utilCommon.cacheManager.getItem(USER_INFO) ? get(JSON.parse(utilCommon.cacheManager.getItem(USER_INFO)), IDENTITY_DELEGATION_FLAG) : null;

    if (identityDelegation) {
      return identityDelegation;
    }
    return null;
  }

  checkRestrictedUrl = () => {
    const openUrlList = [
      ComponentUrls.DownloadDocuments
    ]
    let isRestrictedUrl = true;
    const pathname = this.props.location.pathname.toLowerCase();
    const matchedItem = openUrlList.find(i => pathname.indexOf(i) !== -1);
    if (matchedItem) {
      isRestrictedUrl = false;
    }
    this.setState({ isRestrictedUrl });
    return isRestrictedUrl;
  }

  loadRootChildTags = () => {
    // Find root component exist in system.
    const selector = document.querySelector('#root section');
    // Get Children of the selector, if children found then loader will be automatically off
    if (selector.children && selector.children.length === 0 && !isPublicRoutes()) {
      this.props.dispatch(setAppLoader(true));
    } else {
      // Clear Interval while content session available.
      this.props.dispatch(setAppLoader(false));
      clearInterval(this.midContentSection);
    }
  }

  loadOKTAConfiguration = () => {
    this.props.OIDC.tokenManager.get('idToken').then(tokenInfo => {
      const useInfo = getUserInfo();
      if (tokenInfo && !useInfo) {
        setCookie(cookieKeysEnum["IS-OKTA-VERIFIED"], true, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
        const tokenClaims = get(tokenInfo, 'claims');
        let data = {};
        data.userInfo = tokenClaims;
        data['request_mode'] = LOGIN_USER_MODEL.MEMBER_LOGIN;
        this.props.oktaLogin(data, result => {
          this.props.checkUser()
        });
      }
    });
  }

  loadBannerInfo = () => {
    const self = this;
    self.props.bannerFeature(bannerResponse => {
      if (bannerResponse && bannerResponse.alerttype === 'information') {
        bannerResponse.isShowAlertBanner = bannerResponse.IsDisplayAlert;
        bannerResponse.alerttype = 'info';
      }
      if (bannerResponse) {
        bannerResponse.isShowAlertBanner = bannerResponse.IsDisplayAlert;
        setCookie(cookieKeysEnum["BANNER-DISPLAY"], JSON.stringify(bannerResponse), process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
        self.props.openBanner(bannerResponse);
      }
    });
  }


  verifyUserInfoHandler() {
    // TODO : Verify user information has been update while refresh page.
    const userInfo = getUserInfo();
    if (userInfo && !userInfo.isMember) {
      let data = {};
      data['request_mode'] = LOGIN_USER_MODEL.VERIFY_INFO;
      data.userInfo = userInfo;
      this.props.oktaLogin(data, result => {
        this.props.checkUser()
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isMemberStillLoginInAnotherTab === "false") {
      handleLogoutRedirect();
    }
  }

  // TODO : componentWillReceiveProps hooks to check app isAuthenticated.
  componentWillReceiveProps(nextProps) {
    // Check isAuthenticated State and call method to get user details info.
    if (this.props.authState.isAuthenticated != nextProps.authState.isAuthenticated) {
      if (this.state.isRestrictedUrl) {
        this.loadOKTAConfiguration();
      } else if (isOKTAStorageAvailableWithRequiredkey()) {
        this.loadOKTAConfiguration();
      }
    }

    if (!getCookie(cookieKeysEnum["BANNER-DISPLAY"])) {
      this.loadBannerInfo();
    }

    if (nextProps.userInfo) {
      this.handleDatadogUser(nextProps.userInfo);
    } else {
      this.handleDatadogUser(null);
    }
  }

  handleDatadogUser = (userInfo) => {
    if (userInfo) {
      datadogLogs.setUser({
        MAEUserID: userInfo.MAEUserID,
        memberAccountNumber: userInfo.memberAccountNumber
      });
    } else {
      datadogLogs.clearUser();
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  restrictPage = () => {
    const { pathname } = this.props.location;
    return pathname && pathname.toLowerCase() === StaticPages.StandardsTracking.toLowerCase() ? true : false
  }

  render() {
    const { isSignOutTrigger, isAppLoading, isMiniAppLoading, showToastMessage, content, uuidNumber, isReactTesting, authState: { isPending } } = this.props;
    const { isRedirectUrl, identityDelegation } = this.state;
    const retrictStaticPage = this.restrictPage();
    return (
      <>
        {isMiniAppLoading ? <CustomLoader testId='mini-spinner' /> : null}
        {isAppLoading || isRedirectUrl ? <CustomLoader testId='app-spinner' /> : null}
        {showToastMessage ? (<div className="topAlert"><ToastMessage content={content} uuidNumber={uuidNumber} /></div>) : null}
        <ScrollToTop />
        <SafariAutofillMessage />
        {!isReactTesting && <HeaderComp {...this.props} />}
        {identityDelegation && !retrictStaticPage && <IdentityDelegationBanner {...identityDelegation} {...this.props} />}
        <section style={APP_ROOT_CONTENT_STYLE}>
          {!(isSignOutTrigger || isRedirectUrl) &&
            <Switch>
              <PublicRoute path='/' isRedirectOnPublicPage={true} isOKTAProcess={isPending} exact component={null} {...this.props} />
              <PublicRoute path='/application' isRedirectOnPublicPage={false} component={ApplicationsRoutes} {...this.props} />
              <PublicRoute path='/Msgs' isRedirectOnPublicPage={false} component={StaticPagesRoutes} {...this.props} />
              <PublicRoute path='/aventri-registration' component={AventriRegistration} {...this.props} />
              <PublicRoute path='/download' component={DownloadDocumentsRoutes} {...this.props} />
              <PrivateRoutes path="/MyASTM" component={MyAccountRoutes} {...this.props} isMemberStillLoginInAnotherTab={this.state.isMemberStillLoginInAnotherTab} />
              <PrivateRoutes path="/ballot" component={MyChildAppComponents} {...this.props} isMemberStillLoginInAnotherTab={this.state.isMemberStillLoginInAnotherTab} />
              <PrivateRoutes path="/wi" component={MyChildAppComponents} {...this.props} isMemberStillLoginInAnotherTab={this.state.isMemberStillLoginInAnotherTab} />
              <PrivateRoutes path="/standard" component={MyChildAppComponents} {...this.props} isMemberStillLoginInAnotherTab={this.state.isMemberStillLoginInAnotherTab} />
              <PrivateRoutes path="/meeting" component={MyChildAppComponents} {...this.props} isMemberStillLoginInAnotherTab={this.state.isMemberStillLoginInAnotherTab} />
              <PrivateRoutes path="/RMLogin" component={MyRosterComponents} {...this.props} isMemberStillLoginInAnotherTab={this.state.isMemberStillLoginInAnotherTab} />
              <Redirect from={'/login'} to="/MyASTM" />
              <Route path='*' component={(props) => {
                const { pathname } = props.location;
                if (pathname !== '/auth') {
                  window.open(process.env.REACT_APP_PUB_404_URL, "_self");
                }
                return false;
              }} />
            </Switch>
          }
        </section>
        <FooterComp {...this.props} />
      </>
    );
  }
}

export default withFlagFeature(withOktaAuth(withRouter(App)));